<template>
  <section>
    <button
      type="button"
      class="create-button"
      @click="create"
    >
      <span class="create-button__title">
        {{ $t('quiz.create_quiz_article') }}
      </span>
    </button>
    <AlertModal
      v-if="alertModal"
      @close="closeAlertModal"
      @create="createQuizArticle"
    />
    <ArticleCreateModal
      v-if="articleCreateModal"
      :article-to-create="articleToCreate"
      :articleSourceType="QUIZ_ARTICLE"
      @close="closeArticleCreateModal"
    />
  </section>
</template>

<script>
import { dateTimeNow } from '@/services/DateTimeService'
import { QUIZ_ARTICLE } from '@/model/ValueObject/ArticleCreateTypes'
import NotifyService from '@/services/NotifyService'
import MediaService from '@/services/media/MediaService'
import AlertModal from '@/components/quiz/QuizCreateArticleModal'
import ArticleCreateModal from '@/components/article/ArticleCreateModal'

export default {
  name: 'QuizCreateArticleButton',
  props: {
    quiz: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      QUIZ_ARTICLE,
      articleToCreate: { field: {} },
      alertModal: false,
      articleCreateModal: false
    }
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    }
  },
  components: {
    ArticleCreateModal,
    AlertModal
  },
  methods: {
    mapQuizToArticle (newHeroImage) {
      return {
        site: this.currentUser.defaultSite,
        mainRubric: this.currentUser.defaultRubric,
        type: 'quiz',
        quiz: this.quiz.id,
        field: {
          title: this.quiz.title,
          shortTitle: this.quiz.title,
          subTitle: this.quiz.title,
          firstParagraph: this.quiz.description
        },
        setting: {
          editorialArticle: false,
          internalAuthor: false
        },
        flag: {
          quiz: true
        },
        sharedUsers: [],
        authors: [],
        expanded: {
          sharedUsers: [],
          authors: [],
          heroImage: newHeroImage,
          categories: this.quiz.categories
        },
        categories: this.quiz.categories.map(category => category.id),
        publishedSince: dateTimeNow(),
        orderDate: dateTimeNow()
      }
    },
    create () {
      if (this.quiz.enabled) {
        this.createQuizArticle()
      } else {
        this.showAlertModal()
      }
    },
    async createQuizArticle () {
      this.closeAlertModal()
      if (this.quiz.enabled === false) {
        this.$emit('set-enabled-and-save')
      }

      if (this.quiz.imageInfo) {
        MediaService.createMedia(this.quiz.imageInfo)
          .then(media => {
            this.articleToCreate = this.mapQuizToArticle(media)
            this.showArticleCreateModal()
          })
          .catch(() => {
            NotifyService.setErrorMessage(this.$t('quiz.quiz_article_created_error'))
          })
      } else {
        this.articleToCreate = this.mapQuizToArticle({})
        this.showArticleCreateModal()
      }
    },
    showAlertModal () {
      this.alertModal = true
    },
    closeAlertModal () {
      this.alertModal = false
    },
    showArticleCreateModal () {
      this.articleCreateModal = true
    },
    closeArticleCreateModal () {
      this.articleCreateModal = false
    }
  }
}
</script>

<style scoped lang="scss">
.create-button {
  border-radius: rem(4px);
  border: rem(1px) solid #D1DBE4;
  cursor: pointer;
  background-color: #41B883;
  color: #FFFFFF;
  height: rem(28px);
  &__title {
    padding-left: rem(5px);
    padding-right: rem(5px);
    font-size: rem(13px);
    font-weight: 700;
  }
}
</style>
