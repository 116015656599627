<template>
  <section>
    <div class="main-heading-text">
      {{ $t('quiz.results_header') }}
    </div>
    <div
      v-for="(result, index) in quiz.results"
      :key="`item-${index}`"
      class="results"
    >
      <div class="results__heading">
        <div class="results__heading__text">
          {{ $t('quiz.result_number', { number: index + 1 }) }}
        </div>
        <ModuleFormButton
          v-if="!disabled && index > 0"
          icon="delete"
          @click="removeResult(index)"
        />
      </div>
      <div class="results__inputs">
        <Input
          v-model="result.title"
          id="result_title"
          :label="$t('quiz.result_title')"
          @blur="$v.quiz.results.$each[index].title.$touch()"
          :error="$v.quiz.results.$each[index].title.$error"
          :disabled="disabled"
          class="results__inputs__title"
        />
        <Input
          v-model="result.score"
          :max="scoreMaxPoints"
          id="result_score"
          :label="$t('quiz.result_score')"
          @blur="$v.quiz.results.$each[index].score.$touch()"
          :error="$v.quiz.results.$each[index].score.$error"
          type="number"
          :disabled="disabled || (index === 0 && result.score === 0)"
          class="results__inputs__score"
        />
      </div>
      <Textarea
        v-model="result.description"
        id="result_description"
        :label="$t('quiz.result_description')"
        @blur="$v.quiz.results.$each[index].description.$touch()"
        :error="$v.quiz.results.$each[index].description.$error"
        :rows=2
        :disabled="disabled"
      />
      <ModuleFormPhotoBox
        :key="resultImageKey"
        :image="result.imageInfo"
        :disabled="disabled"
        @set-media="(medias) => setImage(result, medias)"
        @remove-media="removeImage(result)"
        buttons-only
      />
    </div>
    <button
      v-if="!disabled"
      class="add-button results__add-button"
      :title="$t('quiz.add_result')"
      @click="addResult()"
    >
      <PlusIcon class="add-button__icon" />
      {{ $t('quiz.add_result') }}
    </button>
  </section>
</template>

<script>
import store from '@/store'
import Input from '@/components/form/inputs/Input'
import Textarea from '@/components/form/Textarea'
import PlusIcon from '@/assets/img/svg/plus.svg?inline'
import QuizResultModel from '@/model/QuizResultModel'
import ModuleFormPhotoBox from '@/components/shared/ModuleFormPhotoBox'
import ModuleFormButton from '@/components/shared/ModuleFormButton'
import { minLength, required } from 'vuelidate/lib/validators'
const IS_VLM = store.getters.vlm

export default {
  name: 'QuizFormQuestionTabRightColumn',
  props: {
    quiz: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    scoreMaxPoints: {
      type: Number,
      required: true
    }
  },
  components: {
    ModuleFormButton,
    ModuleFormPhotoBox,
    Input,
    Textarea,
    PlusIcon
  },
  data () {
    return {
      resultImageKey: 0
    }
  },
  validations: {
    quiz: {
      results: {
        $each: {
          title: IS_VLM ? {} : {
            required,
            minLength: minLength(1)
          },
          description: IS_VLM ? {} : {
            required,
            minLength: minLength(1)
          },
          score: IS_VLM ? {} : {
            required
          }
        }
      }
    }
  },
  methods: {
    addResult () {
      this.quiz.results.push(this._.cloneDeep(QuizResultModel))
    },
    removeResult (index) {
      this.quiz.results.splice(index, 1)
    },
    setImage (result, medias) {
      medias.forEach(media => {
        result.imageInfo = media
        result.image = media.id
      })
      this.refreshResultImage()
    },
    removeImage (result) {
      result.image = null
      result.imageInfo = null
      this.refreshResultImage()
    },
    refreshResultImage () {
      this.resultImageKey += 1
    }
  },
  created () {
    this.addResult()
  }
}
</script>

<style scoped lang="scss">
.main-heading-text {
  @include font(400 22px "Roboto");
  color: #465674;
  margin-bottom: rem(10px);
}
.title-text {
  color: #8A96AC;
  font-size: rem(14px);
}
.add-button {
  @include font(700 13px "Roboto");
  @include padding(7px 14px);
  @include radius(4px);
  margin-top: 1rem;
  height: rem(30px);
  cursor: pointer;
  background: #6599fe;
  color: #fff;
  border: none;
  transition: background 200ms;
  display: flex;
  justify-content: center;
  align-items: center;
  @include bp(10) {
    &:hover {
      background: darken(#6599fe, 10%);
    }
  }
  &__icon {
    @include size(rem(14px));
    fill: #FFFFFF;
    float: left;
    margin-right: rem(8px);
  }
}
.results {
  background-color: #FFFFFF;
  margin-bottom: 1rem;
  padding: rem(10px);
  border-radius: rem(6px);
  &__heading {
    display: flex;
    &__text {
      flex: 0 0 calc(100% - 25px);
      @include font(700 16px "Roboto");
      color: #465674;
      text-transform: uppercase;
      margin-bottom: rem(10px);
    }
  }
  &__add-button {
    width: 100%;
  }
  &__inputs {
    display: flex;
    gap: 2%;
    &__title {
      flex: 0 0 65%;
    }
    &__score {
      flex: 0 0 33%;
    }
  }
}
</style>
